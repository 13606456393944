header {
  position: relative;
  height: 100vh;
  overflow: hidden;
  color: #ffffff;
  display: flex;
  align-items: center;
}

.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.home-bg-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.header-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.first-section {
  text-align: center;
  margin-bottom: 2rem;
  z-index: 1000;
  position: relative;
}

.first-section h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.first-section h1 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

.first-section h2 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.highlight {
  color: #00a8ff;
}

.social-links {
  margin-top: 1rem;
}

.social-links a {
  color: #ffffff;
  font-size: 1.5rem;
  margin: 0 0.5rem;
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: #00a8ff;
}

.second-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.image-container {
  flex: 1;
  text-align: center;
}

.black-developer {
  width: 200px;
  border-radius: 50%;
  border: 4px solid #00a8ff;
  animation: float 6s ease-in-out infinite;
}

.nice-p {
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 300;
}

.about-me {
  flex: 1;
}

.about-title {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #00a8ff;
}

.about-me p {
  margin-bottom: 0.5rem;
  line-height: 1.4;
  font-size: 0.9rem;
}

.cv-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  background-color: #00a8ff;
  color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.cv-button:hover {
  background-color: #0077b6;
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@media (max-width: 768px) {
  .second-section {
    flex-direction: column;
  }

  .first-section h1 {
    font-size: 2rem;
  }

  .black-developer {
    width: 150px;
  }

  .about-me p {
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .first-section h1 {
    font-size: 1.8rem;
  }

  .first-section h2 {
    font-size: 1rem;
  }

  .black-developer {
    width: 120px;
  }

  .about-title {
    font-size: 1.2rem;
  }

  .cv-button {
    font-size: 0.8rem;
    padding: 0.4rem 0.8rem;
  }
}

