
.projects img {
    width: 20em;
}
.projects{
    width:100%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
 .per-project a{
    text-decoration: none ;
        color: #aaa ;

}
.projects-list{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    
}
.projects h3 {
    justify-content: center;
    display: flex;
}
.visit-site{
    width: 80px;
    height: 30px;
    background-color: black;
    color: white;
    border: white ;
}
.github{
    background-color: white;
    width: 80px;
}
.per-project{
    margin: 0 2rem;
    border-radius: 1em ;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: space-around;
    background-color: #2b2c2f;
    padding: 10px 0;
    margin-bottom: 2em;
    transition: background 0.3s ease-in-out;
    border: #2b2c2f 3px solid;
}
.per-project button{
    margin: 1em 0;
    margin-left: 1em;
    transition: background 0.3s ease-in-out;
    border-radius: .5em;
}
.project-buttons{
    display: flex;
}
.project-names{
    margin: .5em 1em .5em 1em;
    color: white;
    font-size: 20px;
    width: 10em;
}

   .per-project:hover{
      background-color: #1111;
      border: .1em solid #111 ;
    }
    .visit-site:hover,.github:hover{
        background-color: #4e4c4c;
        color: #fff
    }
.portfolio-buttons {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;   
     margin-bottom: 2rem;

}
.portfolio-buttons a button{
    /* background-color: #666;
    color: #fff */
    border: none;
}


@media (max-width: 800px) {
    .projects-list{
        flex-direction: column;
        align-items: center;
        justify-content: center;


    }

    .projects img {
        width: 18em;
    }

 
}

@media (max-width: 400px) {


    .projects img {
        width: 15em;
    }

 
}
