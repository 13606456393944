.skills{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.interests{
    display: grid;
    align-items: center;
    justify-content: center;
}

#skills {
    background-color: #c2baba;   
    border: 0.1em solid #000;
    color: black;
    width: 40vw;
    border-radius: 2em;
    margin-bottom: 2%;
    padding: 2em 0;
    height: 25rem;
    position: relative;
    overflow: hidden;
    transition: background-color 0.7s ease, color 0.4s ease; /* Transition for text color */
  }
  
  #skills::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%; /* Start completely to the left */
    width: 100%;
    height: 100%;
    background-color: white; /* The hover background color */
    transition: left 0.7s ease; /* Smooth left transition */
    z-index: -1; /* Place behind the text */
  }
  
  #skills:hover::before {
    left: 0; /* Move the pseudo-element to cover the container */
  }
  
  #skills:hover {
    border: solid 0.1rem white;
    background-color: rgba(17, 17, 17, 0.1); /* Adjusted the background color */ 
    .check {
    color: black;
  }
  
  }
  
 


.skills-list{
    display: flex;
    justify-content: center;
    align-items: center;
}

.skills-container{
    display: grid;
    gap: 3em;
    grid-template-columns: auto auto;
    justify-content: space-around;
}

.check{
    margin-top: .3em;
    margin-right: .5em;
    color: #000;
}
#skills h4{
     text-align: center;
     margin-bottom: 1em;
     
}

@media(max-width: 990px){
    #skills{
     width: 20em;
    }
   
}
@media (max-width: 900px) {
    .skills{
        width: 100vw;
        flex-direction: column;
    }

    #skills{
        width: 25rem;
        height: auto;
    }
    h5{
        margin-bottom: 0;
        font-size: 16px;
    }
    .check{
        font-size: 14px;
    }
   
}

@media (max-width: 420px) {
   #skills{
    width: 20rem;
    font-size: 8px;
}
h5{
    margin-bottom: 0;
    font-size: 14px;
}
.check{
    font-size: 12px;
}
#skills h4{
    font-size: 20px;
}
}

@media (max-width: 350px) {
    #skills{
     width: 17rem;
     font-size: 8px;
 }
}