.preloader{
    width: 100%; 
    height:100%;
    background-size: 30%;
    position: fixed;
    background-color: black;
    bottom:0;
    left:0;
    display: flex;
    right:0;
    align-items: center;
    overflow: hidden;
    justify-content: center;
    z-index: 100;
}

.preloader .preloader-container{
    display: flex;
    align-items: center;
    justify-content: center;
    height:60px;
    width: 200px;
    font-size: 20px;
    font-weight: 800;
    overflow: hidden;
}
span img {
    width: 2em;
}
