footer{
    background-color: #111;
    text-align: center;
    color: rgb(255, 255, 255);
    margin-top: 2em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 100px 0;
    padding: 3rem 1rem;
}

.footer-links{
    display: flex;
flex-direction: row;    
justify-content: space-between;
}
.footer-icons a {
    margin-right: 20px;
}


@media(max-width: 700px) {
    .footer-links{
        display: flex;
        flex-direction: column;
      margin-bottom: 10px;
    }
    footer{
        margin: 0px;
    }
}