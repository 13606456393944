.form-container {
    max-width: 500px;
    padding: 2em;
    background-color: #1a1a1a;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .form-title {
    color: #ffffff;
    text-align: center;
    margin-bottom: 1em;
    font-size: 1.5em;
  }
  
  .animated-form {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
  
  .input-group {
    position: relative;
  }
  
  .input-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #666;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 0.8em 2.5em;
    background-color: #2a2a2a;
    border: 2px solid #444;
    color: white;
    border-radius: 5px;
    font-size: 1em;
    transition: all 0.3s ease;
  }
  
  input:focus,
  textarea:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }
  
  textarea {
    height: 7em;
    resize: vertical;
  }
  
  .submit-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 0.8em 1.5em;
    font-size: 1em;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  .submit-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .button-icon {
    transition: transform 0.3s ease;
  }
  
  .submit-button:hover .button-icon {
    transform: translateX(5px);
  }
  
  .success-message,
  .error-message {
    margin-top: 1em;
    padding: 0.8em;
    border-radius: 5px;
    text-align: center;
    animation: fadeIn 0.5s ease;
  }
  
  .success-message {
    background-color: #28a745;
    color: white;
  }
  
  .error-message {
    background-color: #dc3545;
    color: white;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @media (max-width: 600px) {
    .form-container {
      padding: 1em;
    }
  
    input,
    textarea {
      font-size: 0.9em;
    }
  }
  
  /* Add this for form animation */
  .animated-form {
    animation: slideIn 0.5s ease;
  }
  
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  