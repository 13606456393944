.App{
    scroll-behavior: smooth;
    color: #fff;
}
.elements{
    z-index: 100;
    position: absolute;
height: 100vh;

}

.contents{
    border-top-left-radius: 3em;
    border-top-right-radius: 3em;
    margin-top: 5rem;
}

.line{
    width: 80%;
    height: 2px;
    background-color: #aaa;
    color:#aaa;
    font-size: 2px;
    border: 2px;
    display: block;
    justify-content: center;
    margin-left: 10%;
    margin-right: 10%;
}
body,html,.App{
    scroll-behavior: smooth;
    overflow-x: hidden !important;
    background-color: black;
width: 100%;
min-height: 100%;
padding: 0;
margin: 0;
font-family: "Poppins", sans-serif;
}

* {
    box-sizing: border-box; /* Ensure padding and border are included in the width */
  }
  a{
    text-decoration: none;
      color: #fff
  }


.topics{
    display: grid;
    justify-content: center;
    width: 100%;
    margin: 6em auto 1em auto;
    text-align: center;
    
}
.playmusic {
    position: fixed;
    bottom: 2vh;
    color: var(--color-secondary);
    font-size: 1.5rem;
    left: 1rem;
    z-index: 100;
  }

.x-icon{
    font-size: 2rem;
    background-color: white;
    border-radius: 50%;
}
@media(max-width: 400px){

    .topics{
        margin-top: 2em;
        font-size: smaller;

}
}






