/* RippleButton.css */
.ripple-container {
    position: relative;
    display: inline-block; /* Ensure the button wraps around the container */
    overflow: hidden; /* Hide overflow for ripple effects */
    border-radius: 0.5rem;

  }
  
  .ripple-button-container {
    position: relative;
    padding: 10px 10px; /* Padding */
    font-size: 16px; /* Font size */
    z-index: 1; /* Ensure button is above ripple */
    border-radius: 10rem;
  }
  .ripple-button{
    background-color: #3498db; /* Button background color */
    color: white; /* Button text color */
    border-radius: 0.5rem; /* Rounded corners */
    border: none;
    transition: background-color 0.3s ease; /* Background transition */
    padding: .5rem 1.5rem;
  }
  .ripple-button:hover {
    background-color: #2980b9; /* Darker background on hover */
  }
  
.ripple {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  pointer-events: none; /* Ignore pointer events */
  z-index: 0;
}

/* Ripple classes for different layers */
.ripple1 {
  width: 150px;
  height: 150px;
  background: rgba(255, 255, 255, 0.3);
  animation: ripple-blink 1.2s infinite ;}

.ripple2 {
  width: 180px;
  height: 180px;
  background: rgba(255, 255, 255, 0.1);
  animation: ripple-blink 1.2s infinite 1.2s; /* Starts after 1s delay */
}

.ripple3 {
  width: 210px;
  height: 210px;
  background: rgba(255, 255, 255, 0.2);
  animation: ripple-blink 1.2s infinite 2.2s; /* Starts after 2s delay */
}

.ripple4 {
  width: 240px;
  height: 240px;
  background: rgba(204, 202, 202, 0.1);
  animation: ripple-blink 1.2s infinite 3.2s; /* Starts after 3s delay */
}

/* Ripple effect animation */
@keyframes ripple-blink {
  0% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(2);
    opacity: 0;
  }
}