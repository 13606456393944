.education-container {
    width: 50%;
    margin: auto;
    .education-list {
      display: flex;
      justify-content: space-between;
      gap: .5rem;
      margin: auto;
      flex-wrap: wrap;
      .education {
        width: 45%;
        display: flex;
        align-items: start;
        margin-bottom: 2rem;
        box-shadow: 3px 3px 10px white;
        transition: box-shadow 1s;
        border: .2px solid white;
        border-radius: 1rem;
        padding: 1rem;

        .list-indent {
          color: white;
          background-color: black;
          padding: 0.5rem 0.5rem;
          border-radius: 50%;
          margin-right: 0.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          overflow: hidden; /* Ensure the pseudo-element doesn't overflow */
          z-index: 1; /* Ensure the text stays above the pseudo-element */
          transition: background-color 0.7s ease, color 0.4s ease; /* Transition for text color */
        }
        .here{
          text-decoration: underline;
        }
  
        .list-indent::before {
          content: "";
          position: absolute;
          top: 0;
          left: -100%; /* Start completely to the left */
          width: 100%;
          height: 100%;
          background-color: white; /* The hover background color */
          transition: left 0.7s ease; /* Smooth left transition */
          z-index: -1; /* Place behind the text */
        }
  
        small {
          color: #7a7a7a;
        }
        h5 {
          color: #54595f;
        }
        p{
          text-align: right;
        }
        .education-info{
          width: 100%;
        }
        .details-container{
          position: relative;
      
           .details{  
              background-color: #111;     
               width: 100%;
              position: absolute;
              display: block;
              right: 0;
              border-radius: .5rem;
              box-sizing: border-box; /* Add this line */
              padding: 1rem;
              opacity: 1;             // Full visibility when details are shown
              transition: opacity 0.5s ease-in-out; 
              p{
                  overflow-wrap: break-word; /* Prevent overflow by wrapping long words */
              }
              small{
                  text-align: end;
              }
           }
           .none-details{
              background-color: black;     
              width: 80%;
             position: absolute;
             display: block;
             right: 0;
             border-radius: .5rem;
             box-sizing: border-box; /* Add this line */
             padding: 1rem;
             height: 0;
             width: 0;
              opacity: 0;    
              transition: opacity 0.5s ease-in-out; // Transition effect for opacity
              p{
               display: none;
              }
           }
        }
      }
      .education:hover {
        box-shadow: none;

        .list-indent::before {
          left: 0; /* Slide the background to fully cover */
        }
  
        .list-indent {
          color: black; /* Change text color when hovered */
        }
      }
    }
  }
  
  @media (max-width: 1200px) {
    .education-container{
      width: 80%;
  .education-list{

  .education{
      width: 40%;
      .details-container{
          .details{
              width: 100%;

          }
      }
  } 
  }
 
}
  }
  @media (max-width: 900px) {
      .education-container{
          width: 90vw;
      .education-list{
          flex-direction: column;
          align-items: center;
          width: 100%;
      .education{
          width: 50%;
          .details-container{
              .details{
                  width: 100%;
  
              }
          }
      } 
      }
     
  }
  }

  @media (max-width: 600px) {
    .education-container{
        width: 90vw;
    .education-list{
        flex-direction: column;
        align-items: center;
        width: 100%;
    .education{
        width: 80%;
        .details-container{
            .details{
                width: 100%;

            }
        }
    } 
    }
   
}
}