.per-contact{    
    background-color: #111;
    border: #000 solid .1em;
    text-align: center;
    color: grey;
    border-radius: 1em;
    width: 20em;
    margin-bottom: 20px;
    padding: 1em;
    transition: background 0.5s ease-in-out;
}



.per-contact img{
    width: 30px;
}
.contacts-parent{
    display: flex;
    flex-direction: column ;
    justify-content: center;
    align-items: center;
}

.per-contact:hover{
    background-color: #1111;  
}

.contact-form{
    display: flex;
    justify-content: space-around;

    margin-bottom: 2rem;
}

@media(max-width: 800px){
        .contact-form{
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .per-contact{
            width: 20em;
        }
    
}

@media(max-width: 400px){
    .per-contact{
        width: 17em;
    }
}

@media (max-width: 350px) {
    #experience{
     width: 14rem;
     font-size: 8px;
 }
}